import * as React from "react"
import Circle from "../assets/image/about/faded-circle.svg"
import CenterImage from "../assets/image/about/about.png"
import Trainer from "../assets/image/about/trainee.svg"
import Courses from "../assets/image/about/course.svg"
import Learning from "../assets/image/about/learning.svg"
import Talent from "../assets/image/about/about-2.png"
import One from "../assets/image/about/1.svg"
import Two from "../assets/image/about/2.svg"
import Three from "../assets/image/about/3.svg"
import Four from "../assets/image/about/4.svg"
import Journey1 from "../assets/image/journey1.png"
import Dot from "../assets/image/about/dot.svg"
import Layout from "../components/layout"
import { MultiLangBody, MultiLangField } from "skillstrainer-resource-library"

const Info = ({ pageContext }) => {
  return (
    <MultiLangBody _key={pageContext.key}>
      <Layout>
        <div className="about">
          <div className="py-80 text-justify lg:text-left text text-2xl line-text overflow-hidden content mx-auto">
            <div className="text-6xl max-w-max mx-auto font-semibold line-heading relative text-center px-10 lg:px-0">
              <img src={Circle} className="absolute -top-40 -left-32" />
              <p className="orange-light1">
                <MultiLangField name={"head1"}>
                  We Are India’s First Exclusive
                </MultiLangField>
              </p>
              <p className="green-dark">
                <MultiLangField name={"head2"}>
                  Vocational Skilling Digital Platform
                </MultiLangField>
              </p>{" "}
            </div>
          </div>
          <div className="bg-dream w-full xl:mx-0 xl:w-4/5 xl:mx-auto px-10 sm:px-20 pt-10 sm:pt-20 pb-40 relative">
            <p>
              <MultiLangField name={"Description"}>
                With a dream to offer quality skilling to anyone, anytime and
                anywhere, <b>SkillsTrainer.in</b> has been launched by Unifiers
                Social Ventures Pvt Ltd, India's leading vocational skilling
                organisation and a NSDC partner. In the last few years of our
                operations, Unifiers projects have impacted over 2 million
                trainees across manufacturing and service sector. To know more
                about our work logon to:
              </MultiLangField>
            </p>
            <p className="red-dark mt-20">
              <a href="http://www.unifiers.in/" target="_default">
                www.unifiers.in
              </a>
            </p>
            <img
              src={Circle}
              className="absolute -bottom-48 -right-48 transform rotate-180"
            />
            <div className="bg-red-dark h-1 w-28 mb-32"></div>
            <div className="flex items-center flex-wrap justify-center md:justify-between md:absolute -bottom-52 left-0 right-0 md:px-10 lg:px-32">
              <div className="about-box blue-dark2">
                <img src={Trainer} className="mt-8" />
                <p className="text-5xl font-semibold my-8">
                  <MultiLangField name={"student"}>20,00,000+</MultiLangField>
                </p>
                <p>
                  <MultiLangField name={"student_impacted"}>
                    Trainees impacted
                  </MultiLangField>
                </p>
              </div>
              <div className="about-box blue-dark2">
                <img src={Courses} className="mt-8" />
                <p className="text-5xl font-semibold my-8">
                  <MultiLangField name={"no_of_courses"}>200+ </MultiLangField>
                </p>
                <p>
                  <MultiLangField name={"courses_types"}>
                    Digital Courses, Trainees and Trainer Manuals
                  </MultiLangField>
                </p>
              </div>
              <div className="about-box blue-dark2">
                <img src={Learning} className="mt-8" />
                <p className="text-5xl font-semibold my-8">
                  <MultiLangField name={"no_of_hours"}>
                    1000+ Hours
                  </MultiLangField>
                </p>
                <p>
                  <MultiLangField name={"learning_types"}>
                    Digital Learning Content
                  </MultiLangField>
                </p>
              </div>
            </div>
            <div className="bg-dream w-full xl:mx-0 xl:w-4/5 xl:mx-auto px-10 sm:px-20 pt-10 sm:pt-20 pb-40 relative">
              <p>
                With a dream to offer quality skilling to anyone, anytime and
                anywhere, <b>SkillsTrainer.in</b> has been launched by Unifiers
                Social Ventures Pvt Ltd, India's leading vocational skilling
                organisation and a NSDC partner. In the last few years of our
                operations, Unifiers projects have impacted over 2 million
                trainees across manufacturing and service sector. To know more
                about our work logon to:
              </p>
              <div className="flex items-center flex-wrap text-5xl font-semibold mt-5 line">
                <p className="red-dark">Anywhere​</p>
                <p className="blue-dark2">&nbsp;&amp;</p>
                <p className="green-light">&nbsp;Anytime</p>
              </div>
              <div className="p-4 bg-white rounded-2xl w-full lg:w-4/5 mt-16 relative">
                <div className="bg-white p-6 rounded-2xl absolute top-full lg:top-1/3 line-text talent-content left-1/2 lg:left-full transform -translate-x-1/2 mt-10 lg:mt-0">
                  <p className="orange-light1 text-4xl font-semibold">
                    Digital Skilling Courses for <br />
                    25% + global population
                  </p>
                  <div className="mt-6">
                    <div className="flex items-center">
                      <div className="h-3 w-3 bg-orange-light1 rounded-full"></div>
                      <p className="ml-5">NSQF mapped/ Modular courses</p>
                    </div>
                    <div className="flex items-center mt-2">
                      <div className="h-3 w-3 bg-orange-light1 rounded-full"></div>
                      <p className="ml-5">
                        B2B, B2G, B2C Training/ Course offerings
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="bg-blue-grad mt-20 md:mt-80 my-20 relative">
              <img src={Dot} className="absolute bottom-5 right-10" />

              <div className="p-10 sm:py-20 sm:px-16">
                <p className="blue-dark2 text-5xl font-semibold">
                  Acquire Skills & Hire Talent
                </p>
                <div className="flex items-center flex-wrap text-5xl font-semibold mt-5 line">
                  <p className="red-dark">Anywhere​</p>
                  <p className="blue-dark2">&nbsp;&amp;</p>
                  <p className="green-light">&nbsp;Anytime</p>
                </div>
                <div className="p-4 bg-white rounded-2xl w-full lg:w-4/5 mt-16 relative">
                  <div className="bg-white p-6 rounded-2xl absolute top-full lg:top-1/3 line-text talent-content left-1/2 lg:left-full transform -translate-x-1/2 mt-10 lg:mt-0">
                    <p className="orange-light1 text-4xl font-semibold">
                      Digital Skilling Courses for 25% + global population
                    </p>
                    <div className="mt-6">
                      <div className="flex items-center">
                        <div className="h-3 w-3 bg-orange-light1 rounded-full"></div>
                        <p className="ml-5">NSQF mapped/ Modular courses</p>
                      </div>
                      <div className="flex items-center mt-2">
                        <div className="h-3 w-3 bg-orange-light1 rounded-full"></div>
                        <p className="ml-5">
                          B2B, B2G, B2C Training/ Course offerings
                        </p>
                      </div>
                    </div>
                  </div>
                  <img src={Talent} className="w-full" />
                </div>
              </div>
            </div>
            <div className="mt-80 mb-20 lg:my-20 mx-auto max-w-max">
              <p className="blue-dark2 text-5xl font-semibold text-center">
                Where We Stand Today...
              </p>
              <div className="flex flex-col xl:flex-row mx-auto">
                <div className="flex flex-col sm:flex-row xl:flex-col">
                  <div className="secondary-card border-blue">
                    <img src={One} />
                    <p className="mt-20">
                      Deep understanding of the Indian and Global vocational
                      skilling space and sound Industry, Government and Academia
                      relationships
                    </p>
                  </div>
                  <div className="secondary-card border-green">
                    <img src={Two} />
                    <p className="mt-20">
                      Launched world's largest NSQF based Virtual Skilling
                      mandate to Digitally train 100,000 Trainees and 5000
                      Trainers
                    </p>
                  </div>
                </div>
                <div className="flex flex-col sm:flex-row">
                  <div className="secondary-card border-orange">
                    <div className="flex items-center">
                      <img src={Three} />
                      <p className="ml-8">Largest owner of</p>
                    </div>
                    <div className="flex items-start mt-28 px-8">
                      <div className="h-3 w-3  bg-orange-light1 rounded-full">
                        &nbsp;&nbsp;
                      </div>
                      <p className="ml-14 -mt-2">
                        NSQF mapped vocational skilling courses IPR in India
                        120+ courses across 10+ sectors including the top 10
                        employment generating Job Roles​
                      </p>
                    </div>
                    <div className="flex items-start mt-20 px-8">
                      <div className="h-3 w-3  bg-orange-light1 rounded-full">
                        &nbsp;&nbsp;
                      </div>
                      <p className="ml-14 -mt-2">
                        Digital Vocational Skilling Courses - 75+ digital
                        courses covering over 50% of India’s organized sector
                        employment​
                      </p>
                    </div>
                  </div>
                  <div className="secondary-card border-yellow">
                    <div className="flex items-center">
                      <img src={Four} />
                      <p className="ml-8">First company in India to</p>
                    </div>
                    <div className="flex items-start mt-20 px-8">
                      <div className="h-3 w-3 bg-orange-light2 rounded-full">
                        &nbsp;&nbsp;
                      </div>
                      <p className="ml-14 -mt-2">
                        Write a National Occupational Standard (NOS)​
                      </p>
                    </div>
                    <div className="flex items-start my-28 px-8">
                      <div className="h-3 w-3 bg-orange-light2 rounded-full">
                        &nbsp;&nbsp;
                      </div>
                      <p className="ml-14 -mt-2">
                        To launch a vocational skilling LMS to offer courses for
                        trainees, trainers & assessors
                      </p>
                    </div>
                    <div className="flex items-start mt-20 px-8">
                      <div className="h-3 w-3 bg-orange-light2 rounded-full">
                        &nbsp;&nbsp;
                      </div>
                      <p className="ml-14 -mt-2">
                        To offer digital skilling courses for the differently
                        abled​
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {/* <div>
            <p className="blue-dark2 text-5xl font-semibold text-center">
              Meet Our <span className="red-dark">Team</span>
            </p>
            <div className="flex flex-wrap my-16 justify-center">
              <div className="team-box">
                <img src={Journey1} className="rounded-lg h-96" />
                <div className="text-center my-5">
                  <p className="text-3xl">Lorem Ipsum</p>
                  <p className="red-dark mt-2">Loream ipsum</p>
                </div>
              </div>
              <div className="team-box">
                <img src={Journey1} className="rounded-lg h-96" />
                <div className="text-center my-5">
                  <p className="text-3xl">Lorem Ipsum</p>
                  <p className="red-dark mt-2">Loream ipsum</p>
                </div>
              </div>
              <div className="team-box">
                <img src={Journey1} className="rounded-lg h-96" />
                <div className="text-center my-5">
                  <p className="text-3xl">Lorem Ipsum</p>
                  <p className="red-dark mt-2">Loream ipsum</p>
                </div>
              </div>
              <div className="team-box">
                <img src={Journey1} className="rounded-lg h-96" />
                <div className="text-center my-5">
                  <p className="text-3xl">Lorem Ipsum</p>
                  <p className="red-dark mt-2">Loream ipsum</p>
                </div>
              </div>
              <div className="team-box">
                <img src={Journey1} className="rounded-lg h-96" />
                <div className="text-center my-5">
                  <p className="text-3xl">Lorem Ipsum</p>
                  <p className="red-dark mt-2">Loream ipsum</p>
                </div>
              </div>
              <div className="team-box">
                <img src={Journey1} className="rounded-lg h-96" />
                <div className="text-center my-5">
                  <p className="text-3xl">Lorem Ipsum</p>
                  <p className="red-dark mt-2">Loream ipsum</p>
                </div>
              </div>
            </div>
          </div> */}
          </div>
        </div>
      </Layout>
    </MultiLangBody>
  )
}

export default Info
